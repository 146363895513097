export default {
  currentPage: 1,
  profilesByPage: [],
  totalPages: "",
  searchMode: false,
  searchParams: {},
  genderList: [
    {
      name: "Female",
      value: 1,
      icon: "fas fa-venus",
      color: "consoleVenus",
    },
    { name: "Male", value: 2, icon: "fas fa-mars", color: "consoleMars" },
    {
      name: "Other",
      value: 3,
      icon: "fas fa-venus-mars",
      color: "consoleOtherGender",
    },
  ],
  orientationList: [
    { name: "Straight", value: 1 },
    { name: "Gay", value: 2 },
    { name: "BI", value: 3 },
  ],
  statusList: {
    0: { name: "Offline", color: "consoleRed" },
    1: { name: "Online", color: "consoleGreen" },
    2: { name: "Away", color: "consoleAmber" },
  },
};
