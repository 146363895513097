//  Each server requires a mapping in this file to populate the authHeader
//  To use this the user must have authenticated

import axios from "axios";
import store from "@/store";
import SockJS from "sockjs-client";

const defaultTimeout = 30000;
const AXIOS = axios.create({ timeout: defaultTimeout });
const consoleGatewayAPI = axios.create({
  baseURL: "/console-gateway/api/",
  timeout: defaultTimeout,
});
const statsAPI = axios.create({
  baseURL: "/stats-microservice/api/stats/",
  timeout: defaultTimeout,
});

const commonHeaders = () => ({
  headers: {
    Authorization: `Bearer ${window.$cookies.get("APN_PRSOC_token")}`,
    "Content-Type": "application/json;charset=UTF-8;",
  },
});

export const normalGateway = () =>
  new SockJS(
    `https://console.${
      process.env.NODE_ENV === "development" ? "textlocalbabes" : "txtitchat"
    }.com/console-gateway/wss/`
  );

export const callLogin = (login) => {
  const SERVICE_TAG = "APN";
  const SERVICE_SECRET = "APNSecret";
  const params = new URLSearchParams();
  params.append("username", login.username);
  params.append("password", login.password);
  params.append("scope", "consoleClient");
  params.append("grant_type", "password");

  const authorisationBasic = window.btoa(`${SERVICE_TAG}:${SERVICE_SECRET}`);

  return AXIOS.post("/api/uaa-service/oauth/token", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${authorisationBasic}`,
    },
    auth: {
      username: SERVICE_TAG,
      password: SERVICE_SECRET,
    },
  });
};

export const callLogout = () =>
  AXIOS.post(`/console-gateway/logout/`, {}, commonHeaders());

export const getUserIdFromUsername = (username) =>
  consoleGatewayAPI.get(`operator/username/${username}`, commonHeaders());

export const getSignedInUserInfo = (operatorId) =>
  consoleGatewayAPI.get(`operator/me/${operatorId}`, commonHeaders());

export const getAllOperatorList = () =>
  consoleGatewayAPI.get("operator", commonHeaders());

export const resetOperatorPassword = (operatorId) => {
  return consoleGatewayAPI.post(
    "operator/password-reset/",
    { id: operatorId },
    commonHeaders()
  );
};

export const disableOperator = (operatorId) => {
  return consoleGatewayAPI.post(
    `operator/disable/${operatorId}`,
    {},
    commonHeaders()
  );
};

// id is specified ? updates : creates new
export const updateOperatorInfo = (
  id,
  firstName,
  lastName,
  username,
  email,
  phoneNumber,
  password,
  enabled,
  created,
  groups,
  jobRole,
  tier
) => {
  return consoleGatewayAPI.post(
    "operator/",
    {
      id,
      firstName,
      lastName,
      username,
      email,
      phoneNumber,
      password,
      enabled,
      created,
      groups,
      jobRole,
      tier,
    },
    commonHeaders()
  );
};
export const getTotalChats = () => {
  return consoleGatewayAPI.get("chat/", commonHeaders());
};

// id is specified ? updates : creates new
export const updateSmsChat = (chatObject) => {
  return consoleGatewayAPI.post(
    "chat/",
    {
      id: chatObject.id,
      chatName: chatObject.chatName,
      shortCode: chatObject.shortCode,
      websiteUrl: chatObject.websiteUrl,
      magicNumberSetId: chatObject.numberSet,
      requiresAdultVerification: chatObject.requiresAdultVerification,
    },
    commonHeaders()
  );
};
export const getChatChargesById = (chatId) => {
  return consoleGatewayAPI.get(`charge/chat/${chatId}`, commonHeaders());
};

export const updateChatCharges = (targetChatCharges) => {
  return consoleGatewayAPI.post(
    "charge/",
    {
      id: targetChatCharges.id,
      smsChatId: targetChatCharges.smsChatId,
      conversationTypeNormal: targetChatCharges.conversationTypeNormal,
      conversationTypeHelp: targetChatCharges.conversationTypeHelp,
      conversationTypeRegister: targetChatCharges.conversationTypeRegister,
      adultVerificationIncentive: targetChatCharges.adultVerificationIncentive,
      startingCredits: targetChatCharges.startingCredits, //Starting Credits
      bronzeCreditAmount: targetChatCharges.bronzeCreditAmount, //Bronze Credits
      bronzePrice: targetChatCharges.bronzePrice, //Bronze Amount
      silverCreditAmount: targetChatCharges.silverCreditAmount, //Silver Credits
      silverPrice: targetChatCharges.silverPrice, //Silver Amount
      goldCreditAmount: targetChatCharges.goldCreditAmount, //Gold Credits
      goldPrice: targetChatCharges.goldPrice, //Gold Amount
      platinumCreditAmount: targetChatCharges.platinumCreditAmount, //Platinum Credits
      platinumPrice: targetChatCharges.platinumPrice, //Platinum Amount
    },
    commonHeaders()
  );
};

//Updates selected profile online field to true, making them an active profile
export const putSelectedChatProfilesOnline = (profileSelectedArray, chatId) => {
  return consoleGatewayAPI.post(
    `profile/select/ids/chat/${chatId}`,
    profileSelectedArray,
    commonHeaders()
  );
};

export const getChatMonitorProfiles = (chatId) => {
  return consoleGatewayAPI.get(
    `profile/monitor/chat/${chatId}`,
    commonHeaders()
  );
};

// id is specified ? updates : creates new
export const processProfile = (profile) => {
  return consoleGatewayAPI.post(
    "profile/",
    {
      id: profile.id,
      username: profile.username,
      gender: profile.personalInformation.gender,
      orientation: profile.personalInformation.orientation,
      bio: profile.bio,
      online: profile.status?.online || false,
      phoneNumber: profile.contactInformation?.phoneNumber || null,
      barred: profile.status?.barred || false,
      chatId: profile.chat.id,
      images: profile.images.primaryImage,
      optOutMarketing: profile.preferences?.marketing,
      optOutReminder: profile.preferences?.reminder,
    },
    commonHeaders()
  );
};

export const callS3 = (fd) => {
  return AXIOS.post("/im/api/s3", fd, {
    headers: {
      Authorization: "Bearer " + window.$cookies.get("APN_PRSOC_token"),
      "Content-Type": "multipart/form-data",
    },
  });
};

//Retrieves list of all profiles against provided pagination page number and search parameters.
// If search parameters are omitted, returns the first 10 profiles
export const getPaginationProfileSearchList = (paginationPage, params) => {
  return consoleGatewayAPI.get(`profiles/search?page=${paginationPage}`, {
    params,
    ...commonHeaders(),
  });
};

export const getSelectedProfileTransactions = (profileId, sortOrder) => {
  const params = new URLSearchParams();
  params.append("sort", sortOrder);
  return consoleGatewayAPI.get(`transaction/profile/${profileId}`, {
    params,
    headers: commonHeaders().headers,
  });
};

export const getProfileConversationArchive = (profileId, paginationAmount) => {
  return consoleGatewayAPI.get(
    `conversation/involvedProfile/profile/${profileId}/?page=0&size=${paginationAmount}`,
    commonHeaders()
  );
};

export const getConversationMessages = (conversationId) => {
  return consoleGatewayAPI.get(
    `message/conversation/${conversationId}`,
    commonHeaders()
  );
};

//Retrieves list of messages assigned to a specific conversation (within Profile Conversation History)
export const getConversationMessageArchive = (conversationId) => {
  return consoleGatewayAPI.get(
    `message/conversation/${conversationId}/archive`,
    commonHeaders()
  );
};

export const getProfileCommandHistory = (id, sortOrder) => {
  const params = new URLSearchParams();
  params.append("sort", sortOrder);
  return consoleGatewayAPI.get(`profile-keyword-commands/profile/${id}`, {
    params,
    headers: commonHeaders().headers,
  });
};

export const getAvailableMagicNumbersBySetId = (id) => {
  return consoleGatewayAPI.get(
    `numbers/available/profile/${id}`,
    commonHeaders()
  );
};

export const getProfileAndCreditInfo = (profileId) => {
  return consoleGatewayAPI.get(`profile/details/${profileId}`, commonHeaders());
};

//Gets total active conversation list and displays profile and operator information against them
export const getTotalConversationDetails = () => {
  return consoleGatewayAPI.get("conversation/queue/details", commonHeaders());
};

//Retrieves the oldest conversation within the queue to which the operator can respond to
export const getNextOperatorQueueConversation = (operatorId) => {
  return consoleGatewayAPI.get(
    `conversation/queue/${operatorId}`,
    commonHeaders()
  );
};

// claimConversation ? assigns conversation to authorised operator : removes conversation from authorised operator
export const assignOrReleaseConversation = (
  conversationId,
  claimConversation
) => {
  return consoleGatewayAPI.post(
    "conversation/claim",
    {
      operatorId: store.state.navigation.authorisedUser.id,
      conversationId: conversationId,
      claimed: claimConversation,
    },
    commonHeaders()
  );
};

export const sendMessage = (conversationId, content, operatorId) => {
  return consoleGatewayAPI.post(
    "outbound-message",
    {
      conversationId,
      content,
      operatorId,
    },
    commonHeaders()
  );
};

export const deleteNoteById = (id) => {
  return consoleGatewayAPI.delete(`info/delete/${id}`, commonHeaders());
};

export const findNotesByConversationAndProfileId = (
  conversationId,
  profileId
) => {
  return consoleGatewayAPI.get(
    `info/conversation/${conversationId}/profile/${profileId}/true`,
    commonHeaders()
  );
};

// id is specified ? updates : creates new
export const updateNote = (
  id,
  profileId,
  conversationId,
  keywordValue,
  categoryId,
  keyword
) => {
  return consoleGatewayAPI.post(
    "info/",
    {
      id,
      profileId,
      conversationId,
      keywordValue,
      categoryId,
      keyword,
    },
    commonHeaders()
  );
};

export const getActiveAlert = () => {
  return consoleGatewayAPI.get("alert/active/101");
};

export const getAllAlerts = () => {
  return consoleGatewayAPI.get("alerts", commonHeaders());
};

export const getAlertById = (alertId) => {
  return consoleGatewayAPI.get(`alert/${alertId}`, commonHeaders());
};

// id is specified ? updates : creates new
export const updateAlert = (alert) => {
  return consoleGatewayAPI.post(
    "alert/",
    {
      id: alert.id,
      siteId: alert.siteId,
      alertContentPrimary: alert.alertContentPrimary,
      alertContentSecondary: alert.alertContentSecondary,
      alertIcon: alert.alertIcon,
      alertColour: alert.alertColour,
      active: alert.active,
      minutesActive: alert.minutesActive,
    },
    commonHeaders()
  );
};

export const getRevenueByRegionAndRange = (region, range) => {
  return statsAPI.get(
    `revenue/region/${region}/range/${range}`,
    commonHeaders()
  );
};

export const getAverageRevenueByRegionAndRange = (region, range) => {
  return statsAPI.get(
    `revenue/average/region/${region}/range/${range}`,
    commonHeaders()
  );
};

export const getMessageCountStatsByRegionAndRange = (region, range) => {
  return statsAPI.get(
    `message/region/${region}/range/${range}`,
    commonHeaders()
  );
};

export const getTransactionStatsByRegionAndRange = (region, range) => {
  return statsAPI.get(
    `transactions/region/${region}/range/${range}`,
    commonHeaders()
  );
};

export const getTemplates = () => {
  return consoleGatewayAPI.get("template", commonHeaders());
};

// id is specified ? updates : creates new
export const updateTemplate = (
  id,
  content,
  description,
  smsChatId,
  templateType
) => {
  return consoleGatewayAPI.post(
    "template/",
    {
      id,
      content,
      smsChatId,
      description,
      templateType,
    },
    commonHeaders()
  );
};

export const getTemplateById = (id) => {
  return consoleGatewayAPI.get(`template/${id}`, commonHeaders());
};

export const getChatKeywordListById = (id) => {
  const params = new URLSearchParams();
  params.append("smsChatId", id);
  return consoleGatewayAPI.get("keyword", {
    params,
    headers: commonHeaders().headers,
  });
};

export const getKeywordById = (id) => {
  return consoleGatewayAPI.get(`keyword/${id}`, commonHeaders());
};

// id is specified ? updates : creates new
export const updateKeyword = (smsChatKeyword) => {
  return consoleGatewayAPI.post(
    "keyword/",
    {
      id: smsChatKeyword.commandId,
      active: smsChatKeyword.active,
      keyword: smsChatKeyword.keywordPhrase,
      smsChatId: smsChatKeyword.smsChatId,
      smsChatTemplateId: smsChatKeyword.keywordTemplateId,
      smsChatCommandSetId: smsChatKeyword.keywordCommandSetId,
      useShortCode: smsChatKeyword.useShortCode,
    },
    commonHeaders()
  );
};

export const getCommandSets = () => {
  return consoleGatewayAPI.get("command-set", commonHeaders());
};

export const getMagicNumberSets = () => {
  return consoleGatewayAPI.get("sets", commonHeaders());
};

// id is specified ? updates : creates new
export const updateMagicNumberSet = (numberSet) => {
  return consoleGatewayAPI.post(
    "set",
    {
      id: numberSet.id,
      name: numberSet.name,
      description: numberSet.description,
      active: numberSet.active,
      used: numberSet.used,
    },
    commonHeaders()
  );
};

export const getMagicNumbersBySetId = (id) => {
  return consoleGatewayAPI.get(`numbers/set/${id}`, commonHeaders());
};

// id is specified ? updates : creates new
export const updateMagicNumber = (magicNumber) => {
  return consoleGatewayAPI.post(
    "number",
    {
      id: magicNumber.id,
      phoneNumber: magicNumber.phoneNumber,
      description: magicNumber.description,
      active: magicNumber.active,
      magicNumberSet: magicNumber.magicNumberSet,
      assigned: magicNumber.assigned,
    },
    commonHeaders()
  );
};
