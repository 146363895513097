import Vue from "vue";
import frontend from "./front-end.vue";
import router from "./router/router";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Axios from "axios";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import VueCookies from "vue-cookies";
import vuetify from "./plugins/vuetify";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";

import "./assets/scss/global.scss";

import store from "@/store";

import TheNavigation from "@/components/global/TheNavigation.vue";
import TheHeader from "@/components/global/TheHeader.vue";
import TheNotification from "@/components/global/TheNotification.vue";
import TheSoundOverlay from "@/components/global/TheSoundOverlay.vue";
import SecondaryText from "@/components/global/SecondaryText.vue";
import OnlineIndicator from "@/components/global/OnlineIndicator.vue";

Vue.prototype.$http = Axios;
Vue.use(Vuesax);
Vue.use(Vuetify);
Vue.use(BootstrapVue);
Vue.use(VueCookies);

Vue.component("TheNavigation", TheNavigation);
Vue.component("TheHeader", TheHeader);
Vue.component("TheNotification", TheNotification);
Vue.component("TheSoundOverlay", TheSoundOverlay);
Vue.component("SecondaryText", SecondaryText);
Vue.component("OnlineIndicator", OnlineIndicator);

new Vue({
  router,
  vuetify,

  store,
  render: (h) => h(frontend),
}).$mount("#frontend");
