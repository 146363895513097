<template>
  <v-tooltip
    top
    :color="statusList[status].color"
    content-class="custom-tooltip rounded-lg"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        x-small
        :color="statusList[status].color"
      >
        fas fa-circle
      </v-icon>
    </template>
    <span class="consoleTextWhite--text">
      {{ statusList[status].name }}
    </span>
  </v-tooltip>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    status: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState("profiles", ["statusList"]),
  },
};
</script>
